<template>
  <v-row>
    <!-- basic -->
    <v-col cols="12">
      <v-card>
        <v-card-title>Tokens</v-card-title>
        <v-card>
          <!--
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-icon class="text--primary me-3">
              {{ icons.mdiMagnify }}
            </v-icon>
          </v-card-title>
          -->

          <v-col cols="12">
            <v-alert
              v-show="alert.show"
              :color="alert.color"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon :color="alert.color">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1 justify-right">
                    {{ alert.message }}
                  </p>
                  <a
                    align="right"
                    href="javascript:void(0)"
                    title="Hide this message"
                    :class="alert.class"
                    @click.prevent="hideAlert"
                  >
                    <span class="text-sm">{{ alert.callToAction }}</span>
                  </a>
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-data-table
            :items="tokens"
            :headers="headers"
            :search="search"
            item-key="id"
            class="elevation-1"
            xxx-sort-by="type"
            :sort-by="['id']"
            :sort-desc="[false, true]"
            xxx-multi-sort
            xxx-group-by="type"
            xxx-show-group-by
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-icon class="text--primary me-3">
                    {{ icons.mdiMagnify }}
                  </v-icon>
                </v-card-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-dialog
                v-model="dialogDelete"
                max-width="500px"
              >
                <v-card>
                  <v-card-title class="text-h6">
                    Are you sure you want to delete this item?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      outlined
                      text
                      @click="deleteItemConfirm"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      outlined
                      text
                      @click="closeDelete"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                @click="deleteItem(item)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import * as QuasrHelper from '@quasr-io/helper'
import {
  mdiMagnify,
  mdiPencilOutline, mdiDeleteOutline,
  mdiEyeOutline,
} from '@mdi/js'
import gql from 'graphql-tag'
import { listTokens } from '../../graphql/queries'
import { deleteToken } from '../../graphql/mutations'
import store from '../../store'

/* eslint-disable */
export default {
  name: 'Tokens',
  props: {
    type: { type: String, default: undefined },
    title: { type: String, default: 'Factors' },
  },
  data() {
    return {
      search: '',
      alert: {
        show: false,
        message: '',
        callToAction: 'OK',
        color: 'success',
        class: 'text-decoration-none success--text pointer',
      },
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          filterable: true,
          value: 'id',
        },
        {
          text: 'Type',
          sortable: true,
          filterable: true,
          value: 'type',
        },
        {
          text: 'Account',
          sortable: true,
          filterable: true,
          value: 'account',
        },
        {
          text: 'Created at',
          sortable: true,
          filterable: true,
          value: 'created_at',
        },
        {
          text: 'Expires at',
          sortable: true,
          filterable: true,
          value: 'expires_at',
        },
        {
          text: 'Status',
          sortable: true,
          filterable: true,
          value: 'status',
        },
        {
          text: 'Actions',
          sortable: false,
          filterable: false,
          value: 'actions',
        },
      ],
      tokens: [],
      token: {
        name: '',
        type: '',
      },
    }
  },
  watch: {
    tenant() {
      this.getTokens()
    },
    account() {
      this.getTokens()
    },
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  async created() {
    if (!this.loginStatus.logged_in) this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/`)
    this.getTokens()
  },
  setup() {
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      loginStatus,
      icons: {
        mdiMagnify,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
      },
    }
  },
  methods: {
    showAlert(message, callToAction, color) {
      this.alert.message = message
      this.alert.callToAction = callToAction
      this.alert.show = true
      this.alert.color = color
      this.alert.class = 'text-decoration-none '.concat(color).concat('--text')
    },
    hideAlert() {
      this.alert.show = false
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteItem (item) {
      this.editedIndex = this.tokens.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    async deleteItemConfirm () {
      await this.deleteToken(this.editedItem.id)
      this.closeDelete()
    },
    async getTokens() {
      const tokens = await store.getAppSyncClient().query({ query: gql(listTokens), variables: { limit: 1000 } })
      this.tokens = tokens.data.listTokens.items
    },
    async deleteToken(id) {
      const deletion = await store.getAppSyncClient().mutate({ mutation: gql(deleteToken), variables: { input: { id: id } } })
      if (deletion.data) this.tokens.splice(this.editedIndex, 1)
      this.showAlert('Token successfully deleted.', 'OK', 'success')
      this.getTokens()
    },
  },
}
/* eslint-enable */
</script>
